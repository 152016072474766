import React, { useState } from "react";
import { ModalComponent } from "..";
import { useAuth } from "../../../customHooks/useAuth";
import { FileUploadInput } from "../../inputs/fileUploadInput";

export const UploadCertificateModal = ({
  modalOpen,
  onModalClose = () => {},
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const { uploadCertificate } = useAuth();

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", uploadedFiles[0]);

    setSubmitting(true);
    uploadCertificate(
      formData,
      () => {
        setSubmitting(false);
      },
      () => {
        setUploadedFiles([]);
        onModalClose();
      }
    );
  };

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={() => {
        setUploadedFiles([]);
        onModalClose();
      }}
      title="Certificate of Registration"
      subTitle="This is required to verify your cooperative as a legal entity."
      modalClassName="!max-h-[480px]"
      isProceedDisabled={uploadedFiles.length < 1 || submitting}
      isLoading={submitting}
      onProceedClick={handleSubmit}
    >
      <FileUploadInput
        maxNumberOfFiles={1}
        handleChange={(files) => {
          setUploadedFiles(Object.values(files));
        }}
        selectedFiles={uploadedFiles.length > 0 && uploadedFiles}
        setSelectedFiles={setUploadedFiles}
      />
    </ModalComponent>
  );
};
