import React, { useState } from "react";
import { decodePaymentAction } from "../../../helperFunctions";
import { TagCard } from "../../cards/tagCard";
import { TransactionDetailsModal } from "../../modals/app_modals/transactionDetailsModal";
import { Pagination } from "../../pagination/pagination";
import { TimeText } from "../../typography/timeText";
import { TableComponent } from "../table";

export const ProductHistoryTable = ({
  data,
  query,
  setQuery = () => {},
  type = "savings",
}) => {
  const headers = decodePaymentAction(type)?.tableHeaders;

  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);

  return (
    <div className="w-full ">
      <div className="w-full overflow-x-scroll">
        <TableComponent
          className="w-full !min-w-max md:!min-w-full"
          isRowClickable={true}
          headers={headers}
          onRowClick={(data) => {
            setSelectedDataId(data);
            setModal("transactionDetailsModal");
            setModalOpen(true);
          }}
          rows={data?.data?.map((item, idx) => {
            const key = `history-${idx}`;
            return {
              id: item?.id,
              items:
                type === "shares"
                  ? [
                      decodePaymentAction(type)?.tableDescription,
                      `${Number(item?.Unit)?.toLocaleString()}`,
                      `₦${Number(item?.Amount)?.toLocaleString()}`,
                      <TimeText
                        timeString={item?.PaymentDateAdded}
                        key={key}
                      />,
                    ]
                  : [
                      decodePaymentAction(type)?.tableDescription,
                      `₦${Number(item?.Amount)?.toLocaleString()}`,
                      <TagCard
                        text={item?.inAppPayment ? "Online" : "Upload"}
                        type={item?.inAppPayment ? "success" : "info"}
                      />,
                      <TimeText
                        timeString={item?.PaymentDateAdded}
                        key={key}
                      />,
                    ],
            };
          })}
        />
      </div>

      <div className="w-full mt-4">
        <Pagination
          limit={query?.limit}
          totalCount={data?.totalCount}
          otherQueryParams={query}
          onPageChange={(e) => setQuery(e)}
        />
      </div>

      <TransactionDetailsModal
        isModalOpen={modalOpen && modal === "transactionDetailsModal"}
        onModalClose={() => {
          setSelectedDataId(null);
          setModal("");
          setModalOpen(false);
        }}
        transaction={data?.data[selectedDataId]}
      />
    </div>
  );
};
