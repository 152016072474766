import { useFormik } from "formik";
import React from "react";
import { ModalComponent } from "..";
import { useDeposit } from "../../../customHooks/useDeposit";
import { useLoans } from "../../../customHooks/useLoans";
import { useSavings } from "../../../customHooks/useSavings";
import { useShares } from "../../../customHooks/useShares";
import { decodePaymentAction } from "../../../helperFunctions";
import { addSavingsAdminSchema } from "../../../validationSchema";
import { DateInput } from "../../inputs/dateInput";
import { FileUploadInput } from "../../inputs/fileUploadInput";
import { TextInput } from "../../inputs/textInput";

export const AdminAddProductModal = ({
  selectedData,
  isModalOpen,
  onModalClose,
  onSuccess,
  type = "savings",
}) => {
  const { doAdminAddSavings } = useSavings();
  const { doAdminAddDeposit } = useDeposit();
  const { doAdminAddShares } = useShares();
  const { adminUploadRepayment } = useLoans();

  const {
    handleSubmit,
    isSubmitting,
    isValid,
    values,
    handleChange,
    handleBlur,
    resetForm,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      amount: "",
      file: undefined,
      date: "",
    },
    validationSchema: addSavingsAdminSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      const payload = {
        coop:
          typeof selectedData?.Cooperative === "object"
            ? selectedData?.Cooperative?.id
            : selectedData?.Cooperative,
        date: new Date(data?.date)?.toISOString(),
        time: new Date(data?.date)?.toISOString(),
        memberId: selectedData?.MemberID?.id,
        amount: data?.amount?.replace(/,/g, ""),
        file: values?.file[0],
      };

      const formData = new FormData();
      formData.append("coop", payload?.coop);
      formData.append("date", payload?.date);
      formData.append("time", payload?.time);
      formData.append("memberId", payload?.memberId);
      formData.append("amount", payload?.amount);
      formData.append("unit", payload?.amount);
      formData.append("file", payload?.file);
      formData.append("loanId", selectedData?.id); //only applies to loans

      if (type === "savings") {
        doAdminAddSavings(
          formData,
          () => {
            setSubmitting(false);
          },
          () => {
            setSubmitting(false);
            onSuccess();
            resetForm();
          }
        );
      } else if (type === "deposit") {
        doAdminAddDeposit(
          formData,
          () => {
            setSubmitting(false);
          },
          () => {
            setSubmitting(false);
            onSuccess();
            resetForm();
          }
        );
      } else if (type === "shares") {
        doAdminAddShares(
          formData,
          () => {
            setSubmitting(false);
          },
          () => {
            setSubmitting(false);
            onSuccess();
            resetForm();
          }
        );
      } else if (type === "loan_repayment") {
        adminUploadRepayment(
          formData,
          () => {
            setSubmitting(false);
          },
          () => {
            setSubmitting(false);
            onSuccess();
            resetForm();
          }
        );
      }
    },
  });

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
        resetForm();
      }}
      title={
        <div className="text-base">
          Add {decodePaymentAction(type)?.subject}
          {" for "}
          <span className="text-pry-green-200 capitalize">
            {selectedData?.MemberID?.User?.FirstName}{" "}
            {selectedData?.MemberID?.User?.LastName}
          </span>
          .
        </div>
      }
      topTitle
      childrenClassName="!mt-[16px]"
      modalClassName="!max-h-[490px]"
      hasButtons
      proceedButtonText="Submit for approval"
      onProceedClick={handleSubmit}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={isSubmitting}
      isProceedDisabled={isSubmitting || !isValid}
    >
      <div className="flex flex-col gap-3">
        <TextInput
          type="text"
          name="amount"
          value={
            values?.amount?.replace(/,/g, "") !== ""
              ? Number(values?.amount?.replace(/,/g, ""))?.toLocaleString()
              : ""
          }
          placeholder={decodePaymentAction(type)?.placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          errors={errors?.amount && touched?.amount && errors?.amount}
          autoComplete="off"
        />
        <DateInput
          name={"date"}
          value={values?.date}
          placeholder="Choose payment date"
          onChange={(val) => {
            setFieldValue("date", val);
          }}
          onBlur={handleBlur}
          hasIcon={false}
          errors={touched?.date && errors?.date && errors?.date}
        />
        <FileUploadInput
          title={"proof of payment"}
          maxNumberOfFiles={1}
          handleChange={(files) => {
            setFieldValue("file", Object.values(files));
          }}
          selectedFiles={values?.file?.length > 0 && values?.file}
          setSelectedFiles={(data) => {
            setFieldValue("file", data);
          }}
          errors={errors?.file && errors?.file}
        />
      </div>
    </ModalComponent>
  );
};
