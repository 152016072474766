import { getApiUrl } from "../api";
import { doNotification } from "./nofitication";

export const convertSize = (size) => {
  if (size > 1048576) {
    return `${Math.ceil(size / 1048576)} MB`;
  }
  return `${Math.ceil(size / 1024)} KB`;
};

export const updateOnboardingCompletionStatus = (profile) => {
  if (!profile || JSON.stringify(profile) === "{}") {
    return {};
  }

  const status = profile?.status;

  //   Initialize the response object
  const responseObject = {
    isAllComplete: false,
    isGetStartedComplete: false,
    isGetStartedStarted: false,
    isOfficialsComplete: false,
    isOfficialsStarted: false,
    isBankDetailsComplete: false,
    isMembershipFormComplete: false,
    onboardingCompletionProgress: 0,
    status: status,
  };

  // Onboarding completion status computation for member profiles
  if (profile?.type === "member") {
    responseObject.isGetStartedStarted =
      profile?.FirstName !== "" ||
      profile?.LastName !== "" ||
      profile?.MiddleName?.replaceAll("#", "") !== "" ||
      profile?.DateOfBirth !== "" ||
      profile?.PhoneNumber?.replaceAll("#", "") !== "" ||
      profile?.Email !== "" ||
      profile?.Address !== "" ||
      profile?.Country?.replaceAll("#", "") !== "" ||
      profile?.Picture !== "" ||
      profile?.State?.replaceAll("#", "") !== "";
    if (
      profile?.FirstName &&
      profile?.LastName &&
      profile?.MiddleName?.replaceAll("#", "") &&
      profile?.DateOfBirth &&
      profile?.PhoneNumber?.replaceAll("#", "") &&
      profile?.Email &&
      profile?.Address &&
      profile?.Country?.replaceAll("#", "") &&
      profile?.State?.replaceAll("#", "") &&
      profile?.Picture
    ) {
      responseObject.isGetStartedComplete = true;
    }

    if (profile?.BankDetail > 0) {
      responseObject.isBankDetailsComplete = true;
    }

    const allStatuses = [
      Boolean(profile?.FirstName),
      Boolean(profile?.LastName),
      Boolean(profile?.MiddleName?.replaceAll("#", "")),
      Boolean(profile?.DateOfBirth),
      Boolean(profile?.PhoneNumber?.replaceAll("#", "")),
      Boolean(profile?.Email),
      Boolean(profile?.Address),
      Boolean(profile?.Country?.replaceAll("#", "")),
      Boolean(profile?.State?.replaceAll("#", "")),
      Boolean(profile?.Picture),
      profile?.BankDetail > 0,
    ];

    const completedCount = allStatuses.filter((el) => el).length;

    responseObject.onboardingCompletionProgress = Math.ceil(
      (completedCount / allStatuses?.length) * 100
    );

    responseObject.isAllComplete = allStatuses?.every((el) => el);

    return responseObject;
  }
  // Onboarding completion status computation for cooperative profiles
  else {
    // If certificate is isRejected, update cetificate completion status to false
    if (profile?.Certificatestatus === "rejected") {
      status.certificate = false;
    }

    //   Check completion status of get started
    if (
      status?.description &&
      status?.byelaw &&
      status?.certificate &&
      status?.logo
    ) {
      responseObject.isGetStartedComplete = true;
    }
    responseObject.isGetStartedStarted = [
      status?.description,
      status?.byelaw,
      status?.certificate,
      status?.logo,
    ]?.includes(true);

    //   Check completion status of Inviting officials
    const officialsCompletionStatus =
      status?.official?.map((off) => {
        return off?.status;
      }) || [];

    if (officialsCompletionStatus?.every((el) => el)) {
      responseObject.isOfficialsComplete = true;
    }
    responseObject.isOfficialsStarted =
      officialsCompletionStatus?.includes(true);

    //   Check completion status of adding bank details
    if (status?.bankDetail) {
      responseObject.isBankDetailsComplete = true;
    }

    //   Check completion status of setting up membership form
    if (status?.membershipForm) {
      responseObject.isMembershipFormComplete = true;
    }

    const allStatuses = [
      status?.description,
      status?.byelaw,
      status?.certificate,
      status?.logo,
      status?.bankDetail,
      responseObject?.isMembershipFormComplete,
      ...officialsCompletionStatus,
    ];

    const completedCount = allStatuses.filter((el) => el).length;

    responseObject.onboardingCompletionProgress = Math.ceil(
      (completedCount / allStatuses?.length) * 100
    );

    responseObject.isAllComplete = allStatuses?.every((el) => el);

    return responseObject;
  }
};

export const getTextFromStatus = (status) => {
  switch (status) {
    case "active":
      return "View document";
    case "pending":
      return "Pending review...";
    case "submitted":
      return "View document";
    case "approved":
      return "View document";
    case "rejected":
      return "Rejected";
    default:
      return "View document";
  }
};

export const decodeUrl = (url) => {
  return `${getApiUrl()}${url?.slice(7)}`;
};

export const range = (start, end, step) => {
  let rangeArray = [];
  let val = start;
  while (val <= end) {
    rangeArray.push(val);
    val = val + step;
  }
  return rangeArray;
};

export const getBase64 = async (file, callback = () => {}) => {
  if (file && file !== "") {
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    return result_base64?.split(",")[1];
  } else {
    return "";
  }
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const doFileDownload = (data, fileName, file) => {
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${file}`);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const decodePaymentAction = (type) => {
  let response = {};

  if (type === "savings") {
    response = {
      title: "How much do you want to save?",
      subject: "Savings",
      action: "saving",
      placeholder: "Enter amount",
      tableHeaders: ["DESCRIPTION", "AMOUNT", "METHOD", "TRANSACTION DATE"],
      adminHistoryTableHeaders: [
        "NAME",
        "AMOUNT",
        "METHOD",
        "TRANSACTION DATE",
      ],
      tableDescription: (
        <div>
          <span className="text-pry-green-300 font-black mr-2 py-1 px-1.5 rounded-md bg-light-green-bg-50 md:bg-transparent">
            ↙
          </span>{" "}
          Savings account <b>credited</b>
        </div>
      ),
      paymentVerificationEndpoint: "Saving/My/Cooperative",
      adminHistoryEndpoint: "Saving/Cooperative/Admin/History",
    };
  } else if (type === "deposit") {
    response = {
      title: "How much do you want to deposit?",
      subject: "Deposit",
      action: "deposit",
      placeholder: "Enter amount",
      tableHeaders: ["DESCRIPTION", "AMOUNT", "METHOD", "TRANSACTION DATE"],
      adminHistoryTableHeaders: [
        "NAME",
        "AMOUNT",
        "METHOD",
        "TRANSACTION DATE",
      ],
      tableDescription: (
        <div>
          <span className="text-pry-green-300 font-black mr-2 py-1 px-1.5 rounded-md bg-light-green-bg-50 md:bg-transparent">
            ↙
          </span>{" "}
          Deposits account <b>credited</b>
        </div>
      ),
      paymentVerificationEndpoint: "Depository/My/Cooperative",
      adminHistoryEndpoint: "Depository/Cooperative/Admin/History",
    };
  } else if (type === "shares") {
    response = {
      title: "How much shares do you want?",
      subject: "Shares",
      action: "share",
      placeholder: "Enter number/unit of shares",
      tableHeaders: [
        "DESCRIPTION",
        "SHARE AMOUNT",
        "SHARE VALUE",
        "TRANSACTION DATE",
      ],
      adminHistoryTableHeaders: [
        "NAME",
        "SHARE AMOUNT",
        "SHARE VALUE",
        "METHOD",
        "TRANSACTION DATE",
      ],
      tableDescription: (
        <div>
          <span className="text-pry-green-300 font-black mr-2 py-1 px-1.5 rounded-md bg-light-green-bg-50 md:bg-transparent">
            ↙
          </span>{" "}
          Shares account <b>credited</b>
        </div>
      ),
      paymentVerificationEndpoint: "Shares/My/Cooperative",
      adminHistoryEndpoint: "Shares/Cooperative/Admin/History",
    };
  } else if (type === "loan_repayment") {
    response = {
      title: "Loan Repayment",
      subject: "Loan Repayment",
      action: "repayment",
      placeholder: "Repayment amount",
      tableHeaders: [
        "DESCRIPTION",
        "SHARE AMOUNT",
        "SHARE VALUE",
        "TRANSACTION DATE",
      ],
      adminHistoryTableHeaders: [
        "NAME",
        "SHARE AMOUNT",
        "SHARE VALUE",
        "TRANSACTION DATE",
      ],
      tableDescription: (
        <div>
          <span className="text-pry-green-300 font-black mr-2">↙</span> Loan
          account credited
        </div>
      ),
      paymentVerificationEndpoint: "Loans/Verify/repayment",
      adminHistoryEndpoint: "Depository/Cooperative/Admin/History",
    };
  } else if (type === "fundCooperativeWallet") {
    response = {
      title: "How much do you want to fund your cooperative wallet with?",
      subject: "",
      action: "coopwallet",
      placeholder: "Enter amount",
      tableHeaders: ["DESCRIPTION", "AMOUNT", "METHOD", "TRANSACTION DATE"],
      adminHistoryTableHeaders: [
        "NAME",
        "AMOUNT",
        "METHOD",
        "TRANSACTION DATE",
      ],
      tableDescription: (
        <div>
          <span className="text-pry-green-300 font-black mr-2 py-1 px-1.5 rounded-md bg-light-green-bg-50 md:bg-transparent">
            ↙
          </span>{" "}
          Wallet <b>credited</b>
        </div>
      ),
      paymentVerificationEndpoint: "Payment/Wallet/Admin",
      adminHistoryEndpoint: "",
    };
  }

  return response;
};

export const isNumericString = (input) => {
  return typeof input === "string" && !Number.isNaN(input);
};

export const isObjectEmpty = (ob) => {
  try {
    if (Object.keys(ob).length > 0) {
      return false;
    } else {
      return true;
    }
  } catch {
    return true;
  }
};

export const numberToString = (number, decimalPlaces = 2) => {
  return Number(stripNumber(String(number))?.replace(/,/g, ""))?.toLocaleString(
    undefined,
    {
      maximumFractionDigits: decimalPlaces,
    }
  );
  // response e.g 12,000 (string)
};

export const stringToNumber = (val) => {
  return Number(String(val)?.replace(/,/g, ""));
  // response e.g 12000 (number)
};

export const stripNumber = (val) => {
  return String(val)?.replace(/,/g, "");
};

export const getDateDifference = (startDate, endDate = new Date()) => {
  const sDate = new Date(startDate);
  const eDate = new Date(endDate);

  const dateDiff = eDate - sDate;

  if (dateDiff / 1_000 <= 60) {
    return `${numberToString(dateDiff / 1_000, 0)} seconds`;
  } else if (dateDiff / 60_000 <= 60) {
    return `${numberToString(dateDiff / 60_000, 0)} minutes`;
  } else if (dateDiff / 3_600_000 <= 24) {
    return `${numberToString(dateDiff / 3_600_000, 0)} hours`;
  } else {
    return `${numberToString(dateDiff / 86_400_000, 0)} days`;
  }
};

export const copyToClipBoard = (text) => {
  navigator?.clipboard?.writeText(text);
  doNotification("Text copied", "info");
};

/**
 * Checks if profile account passed has been approved for operation
 * Always defauts to true for non-cooperative profiles (i.e member profiles)
 */
export const isProfileApproved = (profile) => {
  return profile?.type === "cooperative" ? profile?.isApproved : true;
};

export const decodeLoanStatus = (status) => {
  if (status === "waiting") {
    return {
      text: "Awaiting Guarantor",
      type: "pending",
    };
  } else if (status === "active") {
    return {
      text: "Active",
      type: "info",
    };
  } else if (status === "paidinfull") {
    return {
      text: "Completed",
      type: "success",
    };
  } else if (status === "waitingDisbursement") {
    return {
      text: "Awaiting Disbursement",
      type: "pending",
    };
  } else if (status === "in-progress") {
    return {
      text: "Awaiting Approval",
      type: "pending",
    };
  } else if (status === "denied") {
    return {
      text: "Rejected",
      type: "danger",
    };
  } else if (status === "failedDisbursement") {
    return {
      text: "Failed Disbursement",
      type: "danger",
    };
  } else if (status === "disburse") {
    return {
      text: "Ongoing Disbursement",
      type: "pending",
    };
  }
};

export const decodeWalletType = (walletType) => {
  switch (walletType) {
    case "saving":
      return "Member savings";
    case "registrationfee":
      return "Member registration fee";
    case "loandisbursement":
      return "Loan disbursal";
    case "repaymentdisbursement":
      return "Member loan repayment";
    case "withdrawal":
      return "Cooperative balance withdrawal";
    case "shares":
      return "Member shares purchase";
    case "deposit":
      return "Member deposit";
    default:
      return "Transaction";
  }
};

export const maskEmail = (email) => {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart = localPart.slice(0, 5) + "****";
  return `${maskedLocalPart}@${domain}`;
};

export const maskPhoneNumber = (phone) => {
  const maskedPhone = phone.slice(0, 3) + "***" + phone.slice(6);
  return maskedPhone;
};
