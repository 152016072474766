import React from "react";
import ClickAwayListener from "react-click-away-listener";
import ReactDOM from "react-dom";
import { Button } from "../buttons/button";
import { Icon } from "../icons/icon";

export const ModalComponent = ({
  isModalOpen = false,
  onModalClose = () => {},
  title = "",
  topTitle = false,
  subTitle,
  children,
  childrenClassName = "",
  childrenContainerClassName = "",
  subtitleContainerClassName = "",
  modalClassName = "",
  hasButtons = true,
  proceedButtonText = "Continue",
  proceedButtonVariant = "primary",
  proceedButtonClassName = "",
  onProceedClick = () => {},
  hasCancelButton = false,
  cancelButtonText = "Cancel",
  cancelButtonVariant = "secondary-light-2",
  onCancelClick = () => {},
  isLoading = false,
  isProceedDisabled = false,
  buttonsContainerClassName = "",
  blank = false,
  modalStyle = "center",
  transparentBg = false,
}) => {
  return isModalOpen
    ? ReactDOM.createPortal(
        <div
          className={`
          !fixed left-0 right-0 bottom-0 top-0
          w-full
          !z-[1000]
          ${transparentBg ? "bg-transparent" : "bg-black/60"}
          flex items-end md:items-center justify-center
          p-0 md:p-[20px]
          hide-scroll-bar
          ${
            modalStyle === "side" &&
            `
          md:!p-0 !justify-end
          `
          }
          `}
        >
          <ClickAwayListener
            onClickAway={() => {
              // onModalClose();
            }}
            mouseEvent="mousedown"
            touchEvent="touchstart"
          >
            <div
              className={`
            ${modalStyle === "center" ? `ani-rise` : `ani-slide-left`}
            transition-all
            duration-1000 delay-1000
            bg-white
            w-full md:max-w-[407px]
            h-full max-h-[90%]
            p-[24px] md:p-[40px]
            rounded-t-[8px] md:rounded-[4px]
            translate-y-0
            ${modalClassName}
            flex flex-col
            overflow-scroll
            relative
            justify-between
            hide-scroll-bar
            ${
              modalStyle === "side" &&
              `
            !max-h-full !rounded-none
            `
            }
            `}
            >
              <div
                className={`flex w-full items-center justify-end ${
                  topTitle && title && "!justify-between"
                }`}
              >
                {title && topTitle && (
                  <div className="text-base md:text-[20px] font-medium">
                    {title}
                  </div>
                )}
                <div className="bg-white rounded-full w-fit p-1">
                  <Icon
                    svg="cancel-icon"
                    width={24}
                    height={24}
                    className="hover:!cursor-pointer hover:!scale-90"
                    onClick={onModalClose}
                  />
                </div>
              </div>

              {blank && children}

              {!blank && (
                <div
                  className={`
                mt-6
                overflow-scroll
                h-full
                hide-scroll-bar
                ${childrenContainerClassName}
                `}
                >
                  {title && !topTitle && (
                    <div className="text-base md:text-[20px] font-medium">
                      {title}
                    </div>
                  )}

                  {subTitle && (
                    <div
                      className={`
                    mt-1
                    text-sm
                    text-pry-dark-green-300/80
                    w-full max-w-[308px]
                    ${subtitleContainerClassName}
                    `}
                    >
                      {subTitle}
                    </div>
                  )}

                  <div
                    className={`mt-[32px] hide-scroll-bar ${childrenClassName}`}
                  >
                    {children}
                  </div>
                </div>
              )}

              {hasButtons && !blank && (
                <div
                  className={`
                  mt-4
                  flex flex-row flex-wrap gap-2
                  items-center justify-between
                  ${buttonsContainerClassName}
                  `}
                >
                  {hasCancelButton && (
                    <Button
                      text={cancelButtonText}
                      onClick={onCancelClick}
                      type="button"
                      className="flex-1"
                      loading={false}
                      disabled={false}
                      variation={cancelButtonVariant}
                    />
                  )}
                  <Button
                    text={proceedButtonText}
                    onClick={onProceedClick}
                    type="submit"
                    className={`flex-1 ${proceedButtonClassName}`}
                    loading={isLoading}
                    disabled={isProceedDisabled}
                    variation={proceedButtonVariant}
                  />
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>,
        document.getElementById("portal")
      )
    : null;
};
