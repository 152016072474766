import React from "react";
import { ModalComponent } from "..";

export const ImageViewModal = ({ modalOpen, onModalClose = () => {}, src }) => {
  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title={undefined}
      subTitle={undefined}
      childrenClassName="!mt-0"
      childrenContainerClassName="absolute inset-0 !mt-0 -z-[1]"
      modalClassName="!max-h-[90%] md:!max-w-[703px]"
      hasButtons={false}
      proceedButtonText="Expand"
      proceedButtonClassName="!max-w-[80px]"
      onProceedClick={() => {
        window.open(src, "_blank");
      }}
      proceedButtonVariant="secondary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisable={false}
      buttonsContainerClassName="!justify-center"
    >
      <div className="w-full h-full">
        <img
          src={src}
          className="!w-full !h-full overflow-scroll"
          alt="doc view"
        />
      </div>
    </ModalComponent>
  );
};
