import React from "react";
import { ModalComponent } from "..";

export const ConfirmationModal = ({
  isModalOpen,
  onModalClose,
  title,
  children,
  subtitle,
  onProceed,
  loading,
}) => {
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title={title}
      topTitle
      subTitle={subtitle}
      childrenContainerClassName="!mt-0"
      modalClassName="!max-h-[360px]"
      hasButtons
      proceedButtonText="Yes, I consent"
      proceedButtonVariant="primary"
      onProceedClick={onProceed}
      hasCancelButton
      cancelButtonText="No, Cancel"
      cancelButtonVariant="danger"
      onCancelClick={() => {
        onModalClose();
      }}
      isLoading={loading}
      isProceedDisabled={loading}
    >
      {children && children}
    </ModalComponent>
  );
};
