import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import empty_state from "../../../assets/images/empty_state_cooperatives.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { NumberCard } from "../../../components/cards/numberCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { AddProductModal } from "../../../components/modals/app_modals/addProductModal";
import { AdminAddProductModal } from "../../../components/modals/app_modals/adminAddProductModal";
import { AdminViewProductHistoryModal } from "../../../components/modals/app_modals/adminViewProductHistoryModal";
import { MemberAddProductModal } from "../../../components/modals/app_modals/memberAddProductModal";
import { PaymentModal } from "../../../components/modals/app_modals/paymentModal";
import { SuccessModal } from "../../../components/modals/app_modals/successModal";
import { UploadMemberSavingsModal } from "../../../components/modals/app_modals/uploadMemberSavingsModal";
import { EmptyState } from "../../../components/states/emptyState";
import { CooperativeMemberProductTable } from "../../../components/table/app_tables/cooperativeMemberProductTable";
import { ProductHistoryTable } from "../../../components/table/app_tables/productHistoryTable";
import { useDeposit } from "../../../customHooks/useDeposit";
import { usePayment } from "../../../customHooks/usePayment";
import { usePermissions } from "../../../customHooks/usePermissions";
import { permissionList } from "../../../utils/constants";

export const CooperativeDeposits = ({
  singleCooperative,
  mode,
  isDepositActive,
}) => {
  const defaultQuery = {
    coop: singleCooperative?.id,
    logs: true,
    text: undefined,
    limit: 10,
    offset: 0,
  };

  const [query, setQuery] = useState(defaultQuery);
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [paymentData, setPaymentData] = useState({});

  const { hasPermissionsFor } = usePermissions();

  const { verifyPayment } = usePayment();

  const {
    fetchMyDepositInformation,
    fetchMyDepositHistory,
    fetchCooperativeDepositInformation,
    fetchCooperativeMemberDepositInformation,
  } = useDeposit();

  const depositInformation = useQuery(
    ["depostInformation", { coop: singleCooperative?.id, logs: "false" }],
    fetchMyDepositInformation,
    { enabled: false }
  );

  const depositHistory = useQuery(
    ["depositHistory", query],
    fetchMyDepositHistory,
    { enabled: false }
  );

  const cooperativeDepositInformation = useQuery(
    ["cooperativeDepositInformation", query],
    fetchCooperativeDepositInformation,
    { enabled: false }
  );

  const cooperativeMemberDepositInformation = useQuery(
    ["cooperativeMemberDepositInformation", query],
    fetchCooperativeMemberDepositInformation,
    { enabled: false }
  );

  useEffect(() => {
    mode === "personal" && depositInformation?.refetch();
    mode === "admin" && cooperativeDepositInformation?.refetch();

    setQuery(defaultQuery);
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    mode === "personal" && depositHistory?.refetch();
    mode === "admin" && cooperativeMemberDepositInformation?.refetch();

    // eslint-disable-next-line
  }, [query]);

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const handleUserDepositStart = () => {
    setModal("addDepositModal");
    setModalOpen(true);
  };

  const handleMemberDepositUploadStart = () => {
    setModal("memberAddDepositModal");
    setModalOpen(true);
  };

  return (
    <div className="w-full">
      {/* Card information and action */}
      {(mode === "admin" && cooperativeDepositInformation?.isFetching) ||
      (mode === "personal" &&
        (depositInformation?.isFetching ||
          depositInformation?.isRefetching)) ? (
        <PageLoader />
      ) : (
        <div className="w-full flex flex-row flex-wrap gap-4 items-center">
          <NumberCard
            title={
              mode === "personal" ? (
                <div className="flex items-center gap-3">
                  <div>Deposits Balance</div>
                  <div className="!text-[10px] bg-light-green-bg-500 rounded-full py-0 px-3 w-fit text-pry-dark-green-300">
                    {singleCooperative?.Settings?.deposit?.InterestRate || "0"}%
                    per annum
                  </div>
                </div>
              ) : (
                "Total Deposits Balance"
              )
            }
            value={
              mode === "personal"
                ? depositInformation?.data?.Balance
                : cooperativeDepositInformation?.data?.Balance
            }
            rightChildren={
              mode === "personal" ? (
                <div className="w-full flex flex-col gap-[10px]">
                  <Button
                    text="Add deposit"
                    onClick={handleUserDepositStart}
                    type="button"
                    className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                    loading={false}
                    disabled={!isDepositActive}
                    icon={"plus"}
                    variation="primary-white"
                  />

                  <Button
                    text="Upload deposit"
                    onClick={handleMemberDepositUploadStart}
                    type="button"
                    className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                    loading={false}
                    disabled={!isDepositActive}
                    icon={"arrow-up"}
                    variation="secondary-white"
                  />
                </div>
              ) : (
                /* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ Disabled feature ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */
                <div
                  className={`flex flex-col gap-1 ${
                    !hasPermissionsFor(permissionList.CAN_ADD_PAYMENT) &&
                    "!hidden border-4"
                  }`}
                >
                  <Button
                    text="Upload deposits"
                    onClick={() => {
                      handleModalOpen("uploadDepositModal");
                    }}
                    type="button"
                    className="md:!py-[12px] w-full !max-w-[180px] !text-sm hidden"
                    loading={false}
                    disabled={false}
                    icon={"plus"}
                    variation="secondary-light-3"
                  />
                </div>
                /* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ Disabled feature ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */
              )
            }
          />

          {mode === "personal" && (
            <ContainerCard className="w-full !h-full lg:max-w-[240px] !px-4 !py-[10px]">
              <span className="font-medium text-sm">
                Total interest accrued on your deposit is{" "}
                <span className="text-pry-green-200">
                  ₦{Number(0).toLocaleString()}{" "}
                </span>
                (@ {singleCooperative?.Settings?.deposit?.InterestRate || "0"}%
                p.a)
              </span>
              <Button
                text="Request Withdrawal"
                onClick={() => {}}
                type="button"
                className="md:!py-[12px] w-full !text-sm !mt-[12px] opacity-0 !hover:cursor-default" // Opacity set to 0
                loading={false}
                disabled={false}
                icon={"plus"}
                variation="secondary-light-3"
              />
            </ContainerCard>
          )}
        </div>
      )}

      {/* History  */}
      {!depositHistory?.isFetching &&
        !cooperativeDepositInformation?.isFetching && (
          <div>
            <div className="my-[24px] text-[16px] font-medium flex flex-wrap gap-2 items-center justify-between">
              <div>
                {mode === "personal" ? "Deposit History" : "Members' Deposits"}
              </div>

              {mode === "admin" && (
                <div className="w-full md:w-max md:flex-grow flex items-center md:justify-end gap-2 flex-wrap">
                  <FilterComponent
                    searchPlaceholder={"Search member name, email"}
                    hasExtraFilter={false}
                    handleSearch={(search) => {
                      setQuery({
                        ...query,
                        text: search,
                        limit: 10,
                        offset: 0,
                      });
                    }}
                    mainClassName="!w-full md:!max-w-[286px]"
                    className={"!w-full md:!w-[286px] !py-2"}
                    size="small"
                  />

                  <Button
                    onClick={() => {
                      setModal("adminProductHistoryModal");
                      setModalOpen(true);
                    }}
                    text="View History"
                    type="button"
                    className="md:!py-[8px] w-full md:!max-w-[140px] !text-xs"
                    loading={false}
                    disabled={false}
                    icon={"refresh"}
                    variation="primary"
                  />
                </div>
              )}
            </div>
            {depositHistory?.isFetching ||
            cooperativeMemberDepositInformation?.isFetching ? (
              <div className="mt-[24px]">
                <PageLoader />
              </div>
            ) : (
              <div className="">
                {(mode === "admin" &&
                  !cooperativeMemberDepositInformation?.data?.data?.length) ||
                (mode === "personal" && !depositHistory?.data?.data?.length) ? (
                  <ContainerCard className="!py-12">
                    <EmptyState
                      title={
                        mode === "admin"
                          ? "No deposit found"
                          : "You don’t have any deposits yet"
                      }
                      titleClassName="!text-base"
                      image={<img src={empty_state} alt="empty" />}
                      actionText={
                        mode === "personal" && "+ Add my first deposit"
                      }
                      onClick={handleUserDepositStart}
                      actionButtonVariant="secondary"
                      actionDisabled={!isDepositActive}
                    />
                  </ContainerCard>
                ) : (
                  <div>
                    {mode === "personal" ? (
                      <ProductHistoryTable
                        data={depositHistory?.data}
                        query={query}
                        setQuery={setQuery}
                        type="deposit"
                      />
                    ) : (
                      <CooperativeMemberProductTable
                        data={cooperativeMemberDepositInformation?.data}
                        query={query}
                        setQuery={setQuery}
                        onAddProductClick={(data) => {
                          setSelectedData(data);
                          handleModalOpen("adminAddDepositModal");
                        }}
                        type="deposit"
                        isProductActive={isDepositActive}
                      />
                    )}
                  </div>
                )}
              </div>
            )}{" "}
          </div>
        )}

      {/* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ Disabled feature ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */}
      <UploadMemberSavingsModal
        isModalOpen={
          modalOpen && modal === "uploadDepositModal" && false === true
        }
        onModalClose={handleModalClose}
        singleCooperative={singleCooperative}
        successCallback={() => {
          handleModalOpen("uploadSavingsSuccessModal");
        }}
      />

      <SuccessModal
        message={"Savings uploaded successfully"}
        subText={
          "Once approved, each transaction will be added as savings to the respective members."
        }
        isModalOpen={modalOpen && modal === "uploadSavingsSuccessModal"}
        onModalClose={handleModalClose}
      />
      {/* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ Disabled feature ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */}

      <AdminAddProductModal
        selectedData={selectedData}
        isModalOpen={modalOpen && modal === "adminAddDepositModal"}
        onModalClose={handleModalClose}
        onSuccess={() => {
          handleModalOpen("adminAddDepositSuccessModal");
        }}
        type="deposit"
      />

      <SuccessModal
        message={"Deposit added successfully"}
        subText={
          <div>
            Once approved, it will reflect on{" "}
            <span className="text-pry-green-200">{`${selectedData?.MemberID?.User?.FirstName} ${selectedData?.MemberID?.User?.LastName}'s`}</span>{" "}
            deposits.
          </div>
        }
        isModalOpen={modalOpen && modal === "adminAddDepositSuccessModal"}
        onModalClose={handleModalClose}
      />

      <AddProductModal
        isModalOpen={modalOpen && modal === "addDepositModal"}
        onModalClose={handleModalClose}
        singleCooperative={singleCooperative}
        successCallback={(data) => {
          setPaymentData(data);
          handleModalOpen("paymentModal");
        }}
        type="deposit"
      />

      <PaymentModal
        isModalOpen={modalOpen && modal === "paymentModal"}
        onModalClose={handleModalClose}
        paymentData={paymentData}
        handleVerification={() => {
          verifyPayment(
            {
              coop: singleCooperative?.id,
              trxref: paymentData?.reference,
              amount: paymentData?.amount,
              reference: paymentData?.reference,
              mode: "paystack",
            },
            () => {
              depositInformation?.refetch();
              depositHistory?.refetch();
            },
            () => {
              handleModalClose();
              handleModalOpen("addDepositSuccessModal");
              setPaymentData({});
              setSelectedData({});
              setQuery(defaultQuery);
            },
            "deposit"
          );
        }}
      />

      <SuccessModal
        message={"Deposit added successfully"}
        subText={
          <div>
            You have successfully added{" "}
            <span className="text-pry-green-200">{paymentData?.amount}</span> to
            your {""}
            <span className="text-pry-green-200">
              {singleCooperative?.CoopName}
            </span>{" "}
            deposits.
          </div>
        }
        isModalOpen={modalOpen && modal === "addDepositSuccessModal"}
        onModalClose={handleModalClose}
      />

      <MemberAddProductModal
        cooperative={singleCooperative?.id}
        isModalOpen={modalOpen && modal === "memberAddDepositModal"}
        onModalClose={handleModalClose}
        onSuccess={() => {
          handleModalOpen("memberAddDepositSuccessModal");
        }}
        type="deposit"
      />

      <SuccessModal
        message={"Deposit added successfully"}
        subText={
          <div>
            Your uploaded deposit will reflect in your account once approved.
          </div>
        }
        isModalOpen={modalOpen && modal === "memberAddDepositSuccessModal"}
        onModalClose={handleModalClose}
      />

      <AdminViewProductHistoryModal
        isModalOpen={modalOpen && modal === "adminProductHistoryModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        singleCooperative={singleCooperative}
        type="deposit"
      />
    </div>
  );
};
