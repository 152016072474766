import React, { useState } from "react";
import { ModalComponent } from "..";
import { useSavings } from "../../../customHooks/useSavings";
import { InformationCard } from "../../cards/infoCard";
import { FileUploadInput } from "../../inputs/fileUploadInput";

export const UploadMemberSavingsModal = ({
  isModalOpen,
  onModalClose,
  singleCooperative,
  successCallback = () => {},
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const { downloadBulkSavingsTemplate, doBulkSavingsUpload } = useSavings();

  const handleSubmit = () => {
    setSubmitting(true);

    const formData = new FormData();

    formData.append("form", singleCooperative?.formdetail?.form?.id);
    formData.append("corp", singleCooperative?.id);
    formData.append("coop", singleCooperative?.id);
    formData.append("file", selectedFiles[0]);

    doBulkSavingsUpload(
      formData,
      () => {
        setSubmitting(false);
      },
      () => {
        setSubmitting(false);
        setSelectedFiles([]);
        onModalClose();
        successCallback();
      }
    );
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
        setSelectedFiles([]);
      }}
      title="Add savings in bulk"
      subTitle={
        <InformationCard
          info={
            <div>
              <span
                className="underline text-info-purple font-medium cursor-pointer"
                onClick={() => {
                  downloadBulkSavingsTemplate(
                    {
                      coop: singleCooperative?.id,
                      file: "csv",
                    },
                    `${singleCooperative?.CoopName}_savings_template`
                  );
                }}
              >
                Download
              </span>{" "}
              this excel file and kindly, fill accordingly. Once that is done,
              you can upload the file here.
            </div>
          }
          variant="purple"
          size="mini"
        />
      }
      childrenClassName="!mt-[16px]"
      modalClassName="!max-h-[490px]"
      hasButtons
      proceedButtonText="Submit for approval"
      onProceedClick={handleSubmit}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={submitting}
      isProceedDisabled={selectedFiles?.length < 1 || submitting}
    >
      <FileUploadInput
        handleChange={(data) => {
          setSelectedFiles(Object.values(data));
        }}
        selectedFiles={selectedFiles}
        setSelectedFiles={(data) => {
          setSelectedFiles(data);
        }}
        types="XLS, XLSX, CSV"
        accept=".xls,.xlsx,.csv"
      />
    </ModalComponent>
  );
};
